<template>
    <div>
        <el-form label-position="right" label-width="80px" ref='formInfo' :rules='rules' inline :model="formInfo"
            :size='tabsize'>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label="用户昵称" prop='username'>
                        <el-input v-model="formInfo.username"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label="归属部门">
                        <el-input v-model="deptinfo" @focus=" innerVisible = true " suffix-icon="el-icon-edit">
                        </el-input>
                        <el-dialog width="30%" title="选择上级菜单" :visible.sync="innerVisible" append-to-body>
                            <el-form>
                                <el-tree :data="dept" node-key="id" ref="tree" highlight-current show-checkbox
                                    check-strictly :props="defaultProps" :default-checked-keys='deptarr'>
                                </el-tree>
                                <el-form-item class="btn">
                                    <el-button :size='tabsize' type="primary" @click="choosed">提交</el-button>
                                </el-form-item>
                            </el-form>
                        </el-dialog>

                    </el-form-item>

                </el-col>
            </el-row>

            <el-row>
                <el-col :span='12'>
                    <el-form-item label="手机号" prop='phone'>
                        <el-input v-model="formInfo.phone" maxlength='11'></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label="邮箱" prop='email'>
                        <el-input v-model="formInfo.email"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span='12'>
                    <el-form-item v-if="!formInfo.id" label="用户名称" prop="loginname">
                        <el-input v-model="formInfo.loginname" placeholder="请输入用户名称" />
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item v-if="!formInfo.id" label="用户密码" prop="pwd">
                        <el-input v-model="formInfo.pwd" placeholder="请输入用户密码" type="password" show-password />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label="用户性别">
                        <el-select v-model="formInfo.sex" collapse-tags placeholder="请选择" @change='rolechange'>
                            <el-option v-for="item in sexs" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                </el-col>
                <el-col :span='12'>
                    <el-form-item label="角色">
                        <el-select v-model="rolearr" multiple collapse-tags placeholder="请选择" @change='rolechange'>
                            <el-option v-for="item in role" :key="item.id" :label="item.cname" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span='12'>
                    <el-form-item label="岗位">
                        <el-select v-model="jobarr" multiple collapse-tags placeholder="请选择" @change='jobchange'>
                            <el-option v-for="item in job" :key="item.id" :label="item.jname" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

            </el-row>

            <el-row>
                <el-form-item label="备注" prop='remark' class="remark">
                    <el-input v-model="formInfo.remark"></el-input>
                </el-form-item>
            </el-row>

            <p class="btns">
                <el-button type="primary" :size='tabsize' @click="submitForm('formInfo')">提交</el-button>
            </p>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                innerVisible: false,
                defaultProps: {
                    children: 'children',
                    label: 'cname'
                },
                dept: [],
                job: [],
                role: [],
                jobarr: [],
                rolearr: [],
                deptarr: [],
                deptxarr: [],
                deptinfo: '',
                sexs: [{
                        value: 1,
                        label: '男'
                    },
                    {
                        value: 0,
                        label: '女'
                    },
                    {
                        value: 2,
                        label: '未知'
                    }
                ],
                rules: {
                    loginname: [{
                        required: true,
                        message: "用户名称不能为空",
                        trigger: "blur"
                    }],
                    username: [{
                        required: true,
                        message: "用户昵称不能为空",
                        trigger: "blur"
                    }],
                    pwd: [{
                        required: true,
                        message: "用户密码不能为空",
                        trigger: "blur"
                    }],
                    email: [{
                        type: "email",
                        message: "'请输入正确的邮箱地址",
                        trigger: ["blur", "change"]
                    }],
                    phonenumber: [{
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: "请输入正确的手机号码",
                        trigger: "blur"
                    }]
                }
            }
        },
        props: ['formInfo'],
        mounted() {
            this.dept = this.$parent.$parent.deptData
            this.job = this.$parent.$parent.jobData
            this.role = this.$parent.$parent.roleData
            if (this.formInfo.did) {
                this.jobarr.push(this.formInfo.did)
            }
            console.log(this.formInfo);
            if (this.formInfo.urs) {
                this.formInfo.urs.split(',').map((item) => {
                    this.rolearr.push(Number(item))
                })
            }
            if (this.formInfo.ujs) {
                this.formInfo.ujs.split(',').map((item) => {
                    this.jobarr.push(Number(item))
                })
            }
            if (this.formInfo.uds) {
                this.formInfo.uds.split(',').map((item) => {
                    this.deptarr.push(Number(item))
                })
            }
            // 输入框显示默认选中的归属部门
            this.deptinfo = ''
            if (this.formInfo.uds) {
                this.formInfo.uds.split(',').map((item) => {
                    this.$parent.$parent.deptData1.map((list) => {
                        if (item == list.id) {
                            this.deptinfo += list.cname + ','
                        }
                    })
                })
            }
            this.deptinfo = this.deptinfo.substring(0, this.deptinfo.length - 1)
            // this.jobarr = this.formInfo.ujs.split(',')
            // this.deptarr = this.formInfo.uds.split(',')
        },
        methods: {
            jobchange(val) {
                this.jobarr = val
            },
            rolechange(val) {
                this.rolearr = val
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$parent.$parent.loading = true
                        this.$parent.$parent.formShow = false
                        let url
                        let str
                        if (this.formInfo.id) {
                            url = this.$api.user.edit
                            str = '修改'
                        } else {
                            url = this.$api.user.add
                            str = '添加'
                        }
                        this.formInfo.ujs = this.jobarr + ''
                        this.formInfo.uds = this.deptarr + ''
                        this.formInfo.urs = this.rolearr + ''
                        this.formInfo.udsx = this.deptxarr + ''
                        url(this.formInfo).then(res => {
                            this.$parent.$parent.loading = false
                            if (res.status == 1) {
                                this.$notify.success({
                                    title: 'Info',
                                    message: str + '成功',
                                    showClose: false,
                                    duration: 2000,
                                });
                                this.$parent.$parent.updateList()
                            } else {
                                this.$notify.error({
                                    title: 'Info',
                                    message: res.msg,
                                    showClose: false,
                                    duration: 2000,
                                });
                            }
                            console.log(res);
                        }).catch((error) => {
                            console.log(error);
                        })
                    } else {
                        return false;
                    }
                })
            },
            treecheck(self, all) {
                this.deptarr = all.checkedKeys
                this.deptxarr = all.halfCheckedKeys
            },
            choosed() {
                let arr = this.$refs.tree.getCheckedKeys()
                let info = this.$refs.tree.getCheckedNodes()
                this.deptinfo = ''
                info.map((item) => {
                    this.deptinfo += item.cname + ','
                })
                this.deptinfo = this.deptinfo.substring(0, this.deptinfo.length - 1)
                this.innerVisible = false
                this.deptarr = arr
            },
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
    }
</script>

<style scoped>
    .btns {
        text-align: center;
    }

    .el-tree {
        height: 200px;
        overflow-y: auto;
    }

    .btn {
        /* position: absolute;
        bottom: 0; */
        margin-top: 30px;
        margin-bottom: 0 !important;
    }

    /deep/.el-form-item__content {
        width: 190px;
    }

    /deep/.el-input__inner {
        text-align: left;
    }

    .remark {
        width: 100%;
    }

    .remark/deep/.el-form-item__content {
        width: calc(100% - 92px);
    }

    .el-input-number {
        width: 100%;
    }
</style>